:root {
  /* Colors Variable  */
  --text: #444444;
  --background: #fafafa;
  --primary: #10132c;
  --secondary: #cccccc;
  --highlight: #eeeeee;
  --error: #ea3b3b;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: var(--background);
  font-family: "Graphik";
  font-weight: 400;
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 768px) {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

#app {
  height: 100%;
}

/* Fonts  */
@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url("./assets/fonts/GraphikLCWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url("./assets/fonts/GraphikLCWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url("./assets/fonts/GraphikLCWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/* Scrollbar  */
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;

  &:hover {
    background-color: #a8bbbf;
  }
}

// Icon color
.icon-light {
  color: var(--secondary);

  & :hover {
    color: var(--text);
  }
}

.btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;

  &:hover {
    transform: scale(1.02);
  }

  &:disabled {
    opacity: 0.6;
    filter: saturate(60%);
    cursor: not-allowed;
    &:hover {
      transform: none;
    }
  }
}

.btn-primary {
  background-color: var(--primary);
  color: var(--background);

  &:active {
    background-color: var(--highlight);
  }
}

.btn-rounded {
  padding: 0;
  border-radius: 9999rem;

  &:hover {
    transform: none;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

input[type="radio" i] {
  margin-top: 0;
}

.input {
  border: 1px solid var(--secondary);
  border-radius: 4px;
  padding: 8px;
  font-size: 0.95rem;
  color: var(--text);

  &::placeholder {
    color: var(--secondary);
  }

  &:focus {
    outline-color: var(--primary);
  }
}

.input-error {
  border-color: var(--error);
  color: var(--error);

  &:focus {
    outline-color: var(--error);
  }
}

.text-error {
  font-size: 1rem;
  color: var(--error);
  margin-top: 4px;
  margin-bottom: 8px;
}

label {
  font-size: 0.83rem;
}

.label-error {
  font-size: 0.8rem;
  color: var(--error);
  margin-top: 4px;
  margin-left: 4px;
}

textarea {
  font-family: "Graphik";
  font-weight: 400;
  line-height: 1.2rem;
}
